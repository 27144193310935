<ng-container *ngIf="optimized; else regularImage">
  <ng-container *ngIf="!fill; else fillOptimizedImage">
    <img
      [class]="styleClasses"
      [ngSrc]="image?.cdnBasePath"
      [alt]="image.alt"
      [width]="imageWidth ?? undefined"
      [height]="imageHeight ?? undefined"
      [priority]="priority"
      [loading]="priority ? undefined : 'lazy'"
    />
  </ng-container>
</ng-container>
<ng-template #fillOptimizedImage>
  <img
    [class]="styleClasses"
    [ngSrc]="image?.cdnBasePath"
    [alt]="image.alt"
    [priority]="priority"
    [loading]="priority ? undefined : 'lazy'"
    [fill]="true"
  />
</ng-template>
<ng-template #regularImage>
  <img
    [class]="styleClasses"
    [src]="src"
    [alt]="image.alt"
    [attr.width]="imageWidth ?? undefined"
    [attr.height]="imageHeight ?? undefined"
    [loading]="priority ? undefined : 'lazy'"
  />
</ng-template>
