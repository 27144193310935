/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CampTripLocationPaginateResponse } from '../models/camp-trip-location-paginate-response';
import { CampTripLocationShowResponse } from '../models/camp-trip-location-show-response';
import { deleteCampTripLocationRemove } from '../fn/camp-trip-location/delete-camp-trip-location-remove';
import { DeleteCampTripLocationRemove$Params } from '../fn/camp-trip-location/delete-camp-trip-location-remove';
import { getCampTripLocationIndex } from '../fn/camp-trip-location/get-camp-trip-location-index';
import { GetCampTripLocationIndex$Params } from '../fn/camp-trip-location/get-camp-trip-location-index';
import { getCampTripLocationShow } from '../fn/camp-trip-location/get-camp-trip-location-show';
import { GetCampTripLocationShow$Params } from '../fn/camp-trip-location/get-camp-trip-location-show';
import { patchCampTripLocationUpdate } from '../fn/camp-trip-location/patch-camp-trip-location-update';
import { PatchCampTripLocationUpdate$Params } from '../fn/camp-trip-location/patch-camp-trip-location-update';
import { postCampTripLocationCreate } from '../fn/camp-trip-location/post-camp-trip-location-create';
import { PostCampTripLocationCreate$Params } from '../fn/camp-trip-location/post-camp-trip-location-create';

@Injectable({ providedIn: 'root' })
export class CampTripLocationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCampTripLocationIndex()` */
  static readonly GetCampTripLocationIndexPath = '/v1/camp-trip-location';

  /**
   * list locations.
   *
   * Fetch paginated list of camp-trip-locations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampTripLocationIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripLocationIndex$Response(params?: GetCampTripLocationIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripLocationPaginateResponse>> {
    return getCampTripLocationIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list locations.
   *
   * Fetch paginated list of camp-trip-locations
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampTripLocationIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripLocationIndex(params?: GetCampTripLocationIndex$Params, context?: HttpContext): Observable<CampTripLocationPaginateResponse> {
    return this.getCampTripLocationIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampTripLocationPaginateResponse>): CampTripLocationPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postCampTripLocationCreate()` */
  static readonly PostCampTripLocationCreatePath = '/v1/camp-trip-location';

  /**
   * create a new location.
   *
   * create a new location connected to an exisiting CampTrip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampTripLocationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripLocationCreate$Response(params: PostCampTripLocationCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripLocationShowResponse>> {
    return postCampTripLocationCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * create a new location.
   *
   * create a new location connected to an exisiting CampTrip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampTripLocationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripLocationCreate(params: PostCampTripLocationCreate$Params, context?: HttpContext): Observable<CampTripLocationShowResponse> {
    return this.postCampTripLocationCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampTripLocationShowResponse>): CampTripLocationShowResponse => r.body)
    );
  }

  /** Path part for operation `getCampTripLocationShow()` */
  static readonly GetCampTripLocationShowPath = '/v1/camp-trip-location/{id}';

  /**
   * show a location.
   *
   * Fetch a single camp-trip-location
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampTripLocationShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripLocationShow$Response(params: GetCampTripLocationShow$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripLocationShowResponse>> {
    return getCampTripLocationShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a location.
   *
   * Fetch a single camp-trip-location
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampTripLocationShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripLocationShow(params: GetCampTripLocationShow$Params, context?: HttpContext): Observable<CampTripLocationShowResponse> {
    return this.getCampTripLocationShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampTripLocationShowResponse>): CampTripLocationShowResponse => r.body)
    );
  }

  /** Path part for operation `deleteCampTripLocationRemove()` */
  static readonly DeleteCampTripLocationRemovePath = '/v1/camp-trip-location/{id}';

  /**
   * remove a location.
   *
   * mark location as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampTripLocationRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampTripLocationRemove$Response(params: DeleteCampTripLocationRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripLocationShowResponse>> {
    return deleteCampTripLocationRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a location.
   *
   * mark location as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampTripLocationRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampTripLocationRemove(params: DeleteCampTripLocationRemove$Params, context?: HttpContext): Observable<CampTripLocationShowResponse> {
    return this.deleteCampTripLocationRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampTripLocationShowResponse>): CampTripLocationShowResponse => r.body)
    );
  }

  /** Path part for operation `patchCampTripLocationUpdate()` */
  static readonly PatchCampTripLocationUpdatePath = '/v1/camp-trip-location/{id}';

  /**
   * update a location.
   *
   * update loaction details but not the connected trip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampTripLocationUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampTripLocationUpdate$Response(params: PatchCampTripLocationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripLocationShowResponse>> {
    return patchCampTripLocationUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update a location.
   *
   * update loaction details but not the connected trip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampTripLocationUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampTripLocationUpdate(params: PatchCampTripLocationUpdate$Params, context?: HttpContext): Observable<CampTripLocationShowResponse> {
    return this.patchCampTripLocationUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampTripLocationShowResponse>): CampTripLocationShowResponse => r.body)
    );
  }

}
