import { Component, OnInit } from '@angular/core';
import { ConsentService } from '@features/consent/services/consent.service';
import { IonicModule } from '@ionic/angular';
import { NavigationSidebarComponent } from '@shared/components';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonicModule, NavigationSidebarComponent],
})
export class AppComponent implements OnInit {
  constructor(private readonly consentService: ConsentService) {}

  ngOnInit(): void {
    if (
      this.consentService.shouldAskForConsent('gtm') ||
      this.consentService.shouldAskForConsent('sentry')
    ) {
      this.consentService.askForConsent(
        ['gtm', 'sentry'],
        'Tracking & Fehler Monitoring erlauben?',
        'Dies wird benötigt, um die Benutzererfahrung der Website zu verbessern.',
      );
    }
  }
}
