/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CampCategoryCampListResponse } from '../models/camp-category-camp-list-response';
import { CampCategoryCampShowResponse } from '../models/camp-category-camp-show-response';
import { CampPaginateResponse } from '../models/camp-paginate-response';
import { CampShowResponse } from '../models/camp-show-response';
import { CampShowResponse2 } from '../models/camp-show-response-2';
import { deleteCampCategoryCampRemove } from '../fn/camp/delete-camp-category-camp-remove';
import { DeleteCampCategoryCampRemove$Params } from '../fn/camp/delete-camp-category-camp-remove';
import { deleteCampRemove } from '../fn/camp/delete-camp-remove';
import { DeleteCampRemove$Params } from '../fn/camp/delete-camp-remove';
import { getCampCategoryCampIndex } from '../fn/camp/get-camp-category-camp-index';
import { GetCampCategoryCampIndex$Params } from '../fn/camp/get-camp-category-camp-index';
import { getCampIndex } from '../fn/camp/get-camp-index';
import { GetCampIndex$Params } from '../fn/camp/get-camp-index';
import { getCampShow } from '../fn/camp/get-camp-show';
import { GetCampShow$Params } from '../fn/camp/get-camp-show';
import { patchCampCategoryCampUpdate } from '../fn/camp/patch-camp-category-camp-update';
import { PatchCampCategoryCampUpdate$Params } from '../fn/camp/patch-camp-category-camp-update';
import { patchCampUpdate } from '../fn/camp/patch-camp-update';
import { PatchCampUpdate$Params } from '../fn/camp/patch-camp-update';
import { postCampCategoryCampCreate } from '../fn/camp/post-camp-category-camp-create';
import { PostCampCategoryCampCreate$Params } from '../fn/camp/post-camp-category-camp-create';
import { postCampCreate } from '../fn/camp/post-camp-create';
import { PostCampCreate$Params } from '../fn/camp/post-camp-create';

@Injectable({ providedIn: 'root' })
export class CampService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCampCategoryCampIndex()` */
  static readonly GetCampCategoryCampIndexPath = '/v1/category/{category}/camp';

  /**
   * list camps sorted by priority.
   *
   * Get a not-paginated list of camps, ordered by priority in that specific category.
   * This is used for the card view in the category pages like [/ferienlager/sommer](https://kinder-ferienlager.com/ferienlager/sommer)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampCategoryCampIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryCampIndex$Response(params: GetCampCategoryCampIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<CampCategoryCampListResponse>> {
    return getCampCategoryCampIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list camps sorted by priority.
   *
   * Get a not-paginated list of camps, ordered by priority in that specific category.
   * This is used for the card view in the category pages like [/ferienlager/sommer](https://kinder-ferienlager.com/ferienlager/sommer)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampCategoryCampIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryCampIndex(params: GetCampCategoryCampIndex$Params, context?: HttpContext): Observable<CampCategoryCampListResponse> {
    return this.getCampCategoryCampIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampCategoryCampListResponse>): CampCategoryCampListResponse => r.body)
    );
  }

  /** Path part for operation `postCampCategoryCampCreate()` */
  static readonly PostCampCategoryCampCreatePath = '/v1/category/{category}/camp/{camp}';

  /**
   * Add a category to a camp.
   *
   * Connect a category to a camp with a set priority
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampCategoryCampCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCategoryCampCreate$Response(params: PostCampCategoryCampCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampCategoryCampShowResponse>> {
    return postCampCategoryCampCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a category to a camp.
   *
   * Connect a category to a camp with a set priority
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampCategoryCampCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCategoryCampCreate(params: PostCampCategoryCampCreate$Params, context?: HttpContext): Observable<CampCategoryCampShowResponse> {
    return this.postCampCategoryCampCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampCategoryCampShowResponse>): CampCategoryCampShowResponse => r.body)
    );
  }

  /** Path part for operation `deleteCampCategoryCampRemove()` */
  static readonly DeleteCampCategoryCampRemovePath = '/v1/category/{category}/camp/{camp}';

  /**
   * remove a category from a camp.
   *
   * Remove a category reference from a camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampCategoryCampRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampCategoryCampRemove$Response(params: DeleteCampCategoryCampRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<CampCategoryCampShowResponse>> {
    return deleteCampCategoryCampRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a category from a camp.
   *
   * Remove a category reference from a camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampCategoryCampRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampCategoryCampRemove(params: DeleteCampCategoryCampRemove$Params, context?: HttpContext): Observable<CampCategoryCampShowResponse> {
    return this.deleteCampCategoryCampRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampCategoryCampShowResponse>): CampCategoryCampShowResponse => r.body)
    );
  }

  /** Path part for operation `patchCampCategoryCampUpdate()` */
  static readonly PatchCampCategoryCampUpdatePath = '/v1/category/{category}/camp/{camp}';

  /**
   * Update a category/camp connection.
   *
   * Update additional data of a Camp x Category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampCategoryCampUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampCategoryCampUpdate$Response(params: PatchCampCategoryCampUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampCategoryCampShowResponse>> {
    return patchCampCategoryCampUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a category/camp connection.
   *
   * Update additional data of a Camp x Category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampCategoryCampUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampCategoryCampUpdate(params: PatchCampCategoryCampUpdate$Params, context?: HttpContext): Observable<CampCategoryCampShowResponse> {
    return this.patchCampCategoryCampUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampCategoryCampShowResponse>): CampCategoryCampShowResponse => r.body)
    );
  }

  /** Path part for operation `getCampIndex()` */
  static readonly GetCampIndexPath = '/v1/camp';

  /**
   * list camps.
   *
   * Fetch paginated list of camps with base data to display links
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampIndex$Response(params?: GetCampIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<CampPaginateResponse>> {
    return getCampIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list camps.
   *
   * Fetch paginated list of camps with base data to display links
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampIndex(params?: GetCampIndex$Params, context?: HttpContext): Observable<CampPaginateResponse> {
    return this.getCampIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampPaginateResponse>): CampPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postCampCreate()` */
  static readonly PostCampCreatePath = '/v1/camp';

  /**
   * add a new camp.
   *
   * create a new camp with image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCreate$Response(params: PostCampCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampShowResponse>> {
    return postCampCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * add a new camp.
   *
   * create a new camp with image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCreate(params: PostCampCreate$Params, context?: HttpContext): Observable<CampShowResponse> {
    return this.postCampCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampShowResponse>): CampShowResponse => r.body)
    );
  }

  /** Path part for operation `getCampShow()` */
  static readonly GetCampShowPath = '/v1/camp/{name}';

  /**
   * show a camp.
   *
   * Fetch a single camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampShow$Response(params: GetCampShow$Params, context?: HttpContext): Observable<StrictHttpResponse<CampShowResponse2>> {
    return getCampShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a camp.
   *
   * Fetch a single camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampShow(params: GetCampShow$Params, context?: HttpContext): Observable<CampShowResponse2> {
    return this.getCampShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampShowResponse2>): CampShowResponse2 => r.body)
    );
  }

  /** Path part for operation `deleteCampRemove()` */
  static readonly DeleteCampRemovePath = '/v1/camp/{name}';

  /**
   * remove a camp.
   *
   * Mark camp as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampRemove$Response(params: DeleteCampRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<CampShowResponse2>> {
    return deleteCampRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a camp.
   *
   * Mark camp as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampRemove(params: DeleteCampRemove$Params, context?: HttpContext): Observable<CampShowResponse2> {
    return this.deleteCampRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampShowResponse2>): CampShowResponse2 => r.body)
    );
  }

  /** Path part for operation `patchCampUpdate()` */
  static readonly PatchCampUpdatePath = '/v1/camp/{name}';

  /**
   * update a camp.
   *
   * update camp and new image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampUpdate$Response(params: PatchCampUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampShowResponse2>> {
    return patchCampUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update a camp.
   *
   * update camp and new image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampUpdate(params: PatchCampUpdate$Params, context?: HttpContext): Observable<CampShowResponse2> {
    return this.patchCampUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampShowResponse2>): CampShowResponse2 => r.body)
    );
  }

}
