import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Image2 as Image } from '@kidfl/openapi';

@Component({
  selector: 'app-img',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  encapsulation: ViewEncapsulation.None,
})
export class ImageComponent implements OnInit {
  @Input() image: Partial<Image>;

  @Input() styleClasses = '';
  @Input() imageWidth = '';
  @Input() imageHeight = '';
  @Input() cdnImageWidth = '';
  @Input() cdnImageHeight = '';
  @Input() variant:
    | 'card'
    | 'default'
    | 'kidflcard'
    | 'kidflcardmd'
    | 'kidflcarousellg'
    | 'kidflcategorycard'
    | 'kidflsmall'
    | 'mobile'
    | 'playstore'
    | 'square'
    | 'xxs';
  @Input() optimized = true;
  @Input() priority = false;

  fill = true;

  src: string;

  ngOnInit(): void {
    if (!this.image) {
      throw new Error('image is missing');
    }
    this.initSrc();
    if (!this.image?.cdnBasePath) {
      console.warn('Serving image without cdn', this.image);
    }
  }

  private initSrc(): void {
    if (!this.image.cdnBasePath) {
      this.src = this.image.path;
      this.optimized = false;
      return;
    }
    if (this.variant) {
      this.optimized = false;
      this.src = `${this.image.cdnBasePath}/${this.variant}`;
      return;
    }
    if (this.cdnImageWidth) {
      this.optimized = false;
      this.src = `${this.image.cdnBasePath}/w=${this.cdnImageWidth}`;
      return;
    }
    if (this.cdnImageHeight) {
      this.optimized = false;
      this.src = `${this.image.cdnBasePath}/h=${this.cdnImageHeight}`;
      return;
    }
    this.src = this.image.cdnBasePath;
    if (this.imageWidth && this.imageHeight) {
      this.fill = false;
    }
  }
}
