<ion-header>
  <ion-toolbar color="primary" mode="md">
    <ion-buttons slot="start">
      <ion-button routerLink="/">
        <app-img
          [image]="{
            cdnBasePath:
              'https://imagedelivery.net/rNFpJfph1XeZpzBC6NTnIA/c455ce3c-a984-4f8b-a802-4eb033537f00',
            alt: 'Kinder-Ferienlager.com',
          }"
          imageHeight="32"
          cdnImageHeight="32"
        ></app-img>
      </ion-button>
      <ion-button class="menu-item ion-hide-xl-down" (click)="clickOpenCampsPopover($event)">
        <ion-label> Ferienlagerübersicht </ion-label>
        <ion-icon slot="end" name="caret-down-outline" size="small"></ion-icon>
      </ion-button>
      <ion-popover
        [isOpen]="isCampsPopoverOpen"
        (didDismiss)="isCampsPopoverOpen = false"
        [dismissOnSelect]="true"
        #campsPopover
      >
        <ng-template>
          <ion-list lines="full">
            <ion-item routerLink="/termine" alt="alle freien Termine">
              <ion-label>Freie Termine</ion-label>
            </ion-item>
            <ion-item routerLink="/ferienlager" alt="Ferienlagerübersicht">
              <ion-label>Alle Ferienlager</ion-label>
            </ion-item>
            <ion-item
              routerLink="/ferienlager/ostern"
              title="Alle Ferienlager der Kategorie Frühling"
            >
              <ion-label> Frühling </ion-label>
            </ion-item>
            <ion-item
              routerLink="/ferienlager/sommer"
              title="Alle Ferienlager der Kategorie Sommer"
            >
              <ion-label> Sommer </ion-label>
            </ion-item>
            <ion-item
              routerLink="/ferienlager/herbstferienlager"
              title="Alle Ferienlager der Kategorie Herbst"
            >
              <ion-label> Herbst </ion-label>
            </ion-item>
            <ion-item
              routerLink="/ferienlager/winter"
              title="Alle Ferienlager der Kategorie Winter"
            >
              <ion-label> Winter </ion-label>
            </ion-item>
            <ion-item
              routerLink="/ferienlager/auslandscamps"
              title="Alle Ferienlager der Kategorie Ausland"
            >
              <ion-label> Ausland </ion-label>
            </ion-item>
            <ion-item
              routerLink="/ferienlager/tagescamps"
              title="Alle Ferienlager der Kategorie Tagescamps"
            >
              <ion-label> Tagescamps </ion-label>
            </ion-item>
          </ion-list>
        </ng-template>
      </ion-popover>

      <ion-button class="menu-item ion-hide-xl-down" (click)="clickOnFragenPopover($event)">
        <ion-label> Fragen und Informationen </ion-label>
        <ion-icon slot="end" name="caret-down-outline" size="small"></ion-icon>
      </ion-button>
      <ion-popover
        #fragenPopover
        [isOpen]="isFragenPopoverOpen"
        (didDismiss)="isFragenPopoverOpen = false"
        [dismissOnSelect]="true"
        cssClass="faq-menu-desktop"
      >
        <ng-template>
          <ion-list lines="full">
            <ion-item routerLink="/wer-ist-kinder-ferienlager-com" alt="Über uns">
              <ion-label>Wer ist Kinder-Ferienlager.com?</ion-label>
            </ion-item>
            <ion-item
              routerLink="/wichtige-hinweise"
              alt="Alles was Sie über Ferienlager wissen müssen"
            >
              <ion-label>Wichtige Hinweise zum Ferienlager</ion-label>
            </ion-item>
            <ion-item routerLink="/faqs_ferienlagerangebote" alt="Häufig gestellte Fragen">
              <ion-label>FAQs zu unseren Ferienlagerangeboten</ion-label>
            </ion-item>
          </ion-list>
        </ng-template>
      </ion-popover>
      <ion-button class="menu-item ion-hide-xl-down" routerLink="/bilder" alt="Bildergallerien">
        Bilder
      </ion-button>
      <ion-button class="menu-item ion-hide-xl-down" routerLink="/aktuelles" alt="Unser Blog">
        Aktuelles
      </ion-button>
    </ion-buttons>
    <ng-content></ng-content>
    <ion-buttons slot="end">
      <ion-menu-button class="ion-hide-xl-up"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
