<ion-app ngSkipHydration>
  <ion-menu side="end" content-id="main-content">
    <ion-header>
      <ion-toolbar color="primary" mode="md">
        <ion-title>Kinder-Ferienlager.com</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <app-navigation-sidebar></app-navigation-sidebar>
    </ion-content>
  </ion-menu>

  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
