<ion-item [formGroup]="form" lines="full">
  <ion-input
    [label]="label"
    [placeholder]="placeholder"
    type="text"
    formControlName="value"
    (ngModelChange)="onInputChange()"
  ></ion-input>
  <ion-buttons slot="end">
    <ion-button (click)="onClickConfirmButton()" *ngIf="!isLoading && !successMessage">
      {{ buttonLabel }}
    </ion-button>
    <ion-button *ngIf="isLoading">
      <ion-spinner></ion-spinner>
    </ion-button>
    <ion-button color="success" *ngIf="successMessage">
      <ion-icon name="checkmark"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-label slot="error" *ngIf="errorMessage" style="max-width: 100%">
    <ion-text color="danger">{{ errorMessage }}</ion-text>
  </ion-label>
  <ion-label slot="helper" *ngIf="successMessage" style="max-width: 100%">
    <ion-text color="success">{{ successMessage }}</ion-text>
  </ion-label>
</ion-item>
