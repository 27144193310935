<ion-breadcrumbs
  color="primary"
  class="ion-margin-top ion-padding-bottom"
  [maxItems]="4"
  [itemsAfterCollapse]="2"
>
  <ng-container *ngFor="let link of links">
    <ion-breadcrumb *ngIf="link" [routerLink]="link?.url" [title]="link?.title">
      {{ link?.label }}
    </ion-breadcrumb>
  </ng-container>
</ion-breadcrumbs>
