/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getLocationIndex } from '../fn/location/get-location-index';
import { GetLocationIndex$Params } from '../fn/location/get-location-index';
import { LocationPaginateResponse } from '../models/location-paginate-response';
import { LocationShowResponse } from '../models/location-show-response';
import { postLocationCreate } from '../fn/location/post-location-create';
import { PostLocationCreate$Params } from '../fn/location/post-location-create';

@Injectable({ providedIn: 'root' })
export class LocationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getLocationIndex()` */
  static readonly GetLocationIndexPath = '/v1/location';

  /**
   * list locations.
   *
   * Fetch paginated list of locations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationIndex$Response(params?: GetLocationIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<LocationPaginateResponse>> {
    return getLocationIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list locations.
   *
   * Fetch paginated list of locations
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocationIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationIndex(params?: GetLocationIndex$Params, context?: HttpContext): Observable<LocationPaginateResponse> {
    return this.getLocationIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocationPaginateResponse>): LocationPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postLocationCreate()` */
  static readonly PostLocationCreatePath = '/v1/location';

  /**
   * create a new location.
   *
   * create a new location
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postLocationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLocationCreate$Response(params: PostLocationCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<LocationShowResponse>> {
    return postLocationCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * create a new location.
   *
   * create a new location
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postLocationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLocationCreate(params: PostLocationCreate$Params, context?: HttpContext): Observable<LocationShowResponse> {
    return this.postLocationCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocationShowResponse>): LocationShowResponse => r.body)
    );
  }

}
