/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CampProgramShowResponse2 } from '../../models/camp-program-show-response-2';

export interface DeleteCampProgramRemove$Params {
  name: string;
}

export function deleteCampProgramRemove(http: HttpClient, rootUrl: string, params: DeleteCampProgramRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<CampProgramShowResponse2>> {
  const rb = new RequestBuilder(rootUrl, deleteCampProgramRemove.PATH, 'delete');
  if (params) {
    rb.path('name', params.name, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CampProgramShowResponse2>;
    })
  );
}

deleteCampProgramRemove.PATH = '/v1/camp-program/{name}';
