/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CampCategoryPaginateResponse } from '../models/camp-category-paginate-response';
import { CampCategoryShowResponse } from '../models/camp-category-show-response';
import { CampCategoryShowResponse2 } from '../models/camp-category-show-response-2';
import { deleteCampCategoryRemove } from '../fn/camp-category/delete-camp-category-remove';
import { DeleteCampCategoryRemove$Params } from '../fn/camp-category/delete-camp-category-remove';
import { getCampCategoryIndex } from '../fn/camp-category/get-camp-category-index';
import { GetCampCategoryIndex$Params } from '../fn/camp-category/get-camp-category-index';
import { getCampCategoryShow } from '../fn/camp-category/get-camp-category-show';
import { GetCampCategoryShow$Params } from '../fn/camp-category/get-camp-category-show';
import { patchCampCategoryUpdate } from '../fn/camp-category/patch-camp-category-update';
import { PatchCampCategoryUpdate$Params } from '../fn/camp-category/patch-camp-category-update';
import { postCampCategoryCreate } from '../fn/camp-category/post-camp-category-create';
import { PostCampCategoryCreate$Params } from '../fn/camp-category/post-camp-category-create';

@Injectable({ providedIn: 'root' })
export class CampCategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCampCategoryIndex()` */
  static readonly GetCampCategoryIndexPath = '/v1/camp-category';

  /**
   * list categories.
   *
   * Fetch a paginated list of galleries.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampCategoryIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryIndex$Response(params?: GetCampCategoryIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<CampCategoryPaginateResponse>> {
    return getCampCategoryIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list categories.
   *
   * Fetch a paginated list of galleries.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampCategoryIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryIndex(params?: GetCampCategoryIndex$Params, context?: HttpContext): Observable<CampCategoryPaginateResponse> {
    return this.getCampCategoryIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampCategoryPaginateResponse>): CampCategoryPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postCampCategoryCreate()` */
  static readonly PostCampCategoryCreatePath = '/v1/camp-category';

  /**
   * Add a new category.
   *
   * create a new gallery and image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampCategoryCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCategoryCreate$Response(params: PostCampCategoryCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampCategoryShowResponse>> {
    return postCampCategoryCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a new category.
   *
   * create a new gallery and image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampCategoryCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCategoryCreate(params: PostCampCategoryCreate$Params, context?: HttpContext): Observable<CampCategoryShowResponse> {
    return this.postCampCategoryCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampCategoryShowResponse>): CampCategoryShowResponse => r.body)
    );
  }

  /** Path part for operation `getCampCategoryShow()` */
  static readonly GetCampCategoryShowPath = '/v1/camp-category/{name}';

  /**
   * show a category.
   *
   * Fetch a single category with image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampCategoryShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryShow$Response(params: GetCampCategoryShow$Params, context?: HttpContext): Observable<StrictHttpResponse<CampCategoryShowResponse2>> {
    return getCampCategoryShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a category.
   *
   * Fetch a single category with image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampCategoryShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryShow(params: GetCampCategoryShow$Params, context?: HttpContext): Observable<CampCategoryShowResponse2> {
    return this.getCampCategoryShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampCategoryShowResponse2>): CampCategoryShowResponse2 => r.body)
    );
  }

  /** Path part for operation `deleteCampCategoryRemove()` */
  static readonly DeleteCampCategoryRemovePath = '/v1/camp-category/{name}';

  /**
   * remove a category.
   *
   * Mark a category as removed. The image will persist.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampCategoryRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampCategoryRemove$Response(params: DeleteCampCategoryRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<CampCategoryShowResponse2>> {
    return deleteCampCategoryRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a category.
   *
   * Mark a category as removed. The image will persist.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampCategoryRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampCategoryRemove(params: DeleteCampCategoryRemove$Params, context?: HttpContext): Observable<CampCategoryShowResponse2> {
    return this.deleteCampCategoryRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampCategoryShowResponse2>): CampCategoryShowResponse2 => r.body)
    );
  }

  /** Path part for operation `patchCampCategoryUpdate()` */
  static readonly PatchCampCategoryUpdatePath = '/v1/camp-category/{name}';

  /**
   * update a category.
   *
   * update category and set new image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampCategoryUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampCategoryUpdate$Response(params: PatchCampCategoryUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampCategoryShowResponse2>> {
    return patchCampCategoryUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update a category.
   *
   * update category and set new image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampCategoryUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampCategoryUpdate(params: PatchCampCategoryUpdate$Params, context?: HttpContext): Observable<CampCategoryShowResponse2> {
    return this.patchCampCategoryUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampCategoryShowResponse2>): CampCategoryShowResponse2 => r.body)
    );
  }

}
