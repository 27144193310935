/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ImagePaginateResponse } from '../../models/image-paginate-response';

export interface GetImageIndex$Params {

/**
 * Pagination offset
 */
  page?: number;

/**
 * Pagination limit
 */
  limit?: number;

/**
 * Pagination order field ascending
 */
  orderAsc?: 'id' | 'createdAt';

/**
 * Pagination order field descending
 */
  orderDesc?: 'id' | 'createdAt';

/**
 * Filter by image alt text
 */
  alt?: string;

/**
 * Filter by image description
 */
  description?: string;

/**
 * Filter by gallery name
 */
  gallery?: string;
}

export function getImageIndex(http: HttpClient, rootUrl: string, params?: GetImageIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<ImagePaginateResponse>> {
  const rb = new RequestBuilder(rootUrl, getImageIndex.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('limit', params.limit, {});
    rb.query('orderAsc', params.orderAsc, {});
    rb.query('orderDesc', params.orderDesc, {});
    rb.query('alt', params.alt, {});
    rb.query('description', params.description, {});
    rb.query('gallery', params.gallery, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ImagePaginateResponse>;
    })
  );
}

getImageIndex.PATH = '/v1/image';
