/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FileStoreResponse } from '../../models/file-store-response';

export interface PostFileCreate$Params {
      body: {
'filecontent'?: Blob;
'filename'?: string;
}
}

export function postFileCreate(http: HttpClient, rootUrl: string, params: PostFileCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FileStoreResponse>> {
  const rb = new RequestBuilder(rootUrl, postFileCreate.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FileStoreResponse>;
    })
  );
}

postFileCreate.PATH = '/v1/file';
