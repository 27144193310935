import { effect, inject, Injectable, signal, untracked } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ConsentService } from './consent.service';
import * as Sentry from '@sentry/angular';
import { environment } from '../../../../environments/environment';
import type { Client } from '@sentry/types';
import { WindowService } from '@core/services';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  private windowService = inject(WindowService);
  private alertCtrl = inject(AlertController);
  private consentService = inject(ConsentService);
  private isEnabled = toSignal(this.consentService.isConsentGiven('sentry'));
  private sentryClient = signal<Client | null>(null);

  constructor() {
    effect(() => {
      const isEnabled = this.isEnabled();
      const wasEnabled = !!untracked(() => this.sentryClient());
      if (isEnabled && wasEnabled) {
        // reactivate by reload
        this.reload();
        return;
      }
      if (!isEnabled) {
        untracked(() => this.sentryClient()?.close());
        return;
      }
      untracked(() => this.sentryClient.set(this.init()));
    });
  }

  private init(): Client {
    return Sentry.init({
      environment: environment.name,
      dsn: 'https://da6db0a75823a86a710f2c2dfb152dc9@o4508265454764032.ingest.de.sentry.io/4508265456205904',
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/kinder-ferienlager\.com/,
        /^https:\/\/kinder-ferienlager\.m12n\.dev/,
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  private async reload(): Promise<void> {
    const alert = await this.alertCtrl.create({
      message: 'Um Sentry komplett zu deaktivieren, muss die Seite neu geladen werden',
      buttons: [
        {
          text: 'OK',
          handler: () => this.windowService.nativeWindow?.location.reload(),
        },
      ],
    });
    await alert.present();
  }
}
