/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CampProgramPaginateResponse } from '../models/camp-program-paginate-response';
import { CampProgramShowResponse } from '../models/camp-program-show-response';
import { CampProgramShowResponse2 } from '../models/camp-program-show-response-2';
import { deleteCampProgramRemove } from '../fn/camp-program/delete-camp-program-remove';
import { DeleteCampProgramRemove$Params } from '../fn/camp-program/delete-camp-program-remove';
import { getCampProgramIndex } from '../fn/camp-program/get-camp-program-index';
import { GetCampProgramIndex$Params } from '../fn/camp-program/get-camp-program-index';
import { getCampProgramShow } from '../fn/camp-program/get-camp-program-show';
import { GetCampProgramShow$Params } from '../fn/camp-program/get-camp-program-show';
import { patchCampProgramUpdate } from '../fn/camp-program/patch-camp-program-update';
import { PatchCampProgramUpdate$Params } from '../fn/camp-program/patch-camp-program-update';
import { postCampProgramCreate } from '../fn/camp-program/post-camp-program-create';
import { PostCampProgramCreate$Params } from '../fn/camp-program/post-camp-program-create';

@Injectable({ providedIn: 'root' })
export class CampProgramService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCampProgramIndex()` */
  static readonly GetCampProgramIndexPath = '/v1/camp-program';

  /**
   * list programs.
   *
   * Fetch a paginated list of camp programs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampProgramIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampProgramIndex$Response(params?: GetCampProgramIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<CampProgramPaginateResponse>> {
    return getCampProgramIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list programs.
   *
   * Fetch a paginated list of camp programs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampProgramIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampProgramIndex(params?: GetCampProgramIndex$Params, context?: HttpContext): Observable<CampProgramPaginateResponse> {
    return this.getCampProgramIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampProgramPaginateResponse>): CampProgramPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postCampProgramCreate()` */
  static readonly PostCampProgramCreatePath = '/v1/camp-program';

  /**
   * add a new program.
   *
   * create a new program connected to a camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampProgramCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampProgramCreate$Response(params: PostCampProgramCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampProgramShowResponse>> {
    return postCampProgramCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * add a new program.
   *
   * create a new program connected to a camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampProgramCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampProgramCreate(params: PostCampProgramCreate$Params, context?: HttpContext): Observable<CampProgramShowResponse> {
    return this.postCampProgramCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampProgramShowResponse>): CampProgramShowResponse => r.body)
    );
  }

  /** Path part for operation `getCampProgramShow()` */
  static readonly GetCampProgramShowPath = '/v1/camp-program/{name}';

  /**
   * show a program.
   *
   * Fetch a single program
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampProgramShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampProgramShow$Response(params: GetCampProgramShow$Params, context?: HttpContext): Observable<StrictHttpResponse<CampProgramShowResponse2>> {
    return getCampProgramShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a program.
   *
   * Fetch a single program
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampProgramShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampProgramShow(params: GetCampProgramShow$Params, context?: HttpContext): Observable<CampProgramShowResponse2> {
    return this.getCampProgramShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampProgramShowResponse2>): CampProgramShowResponse2 => r.body)
    );
  }

  /** Path part for operation `deleteCampProgramRemove()` */
  static readonly DeleteCampProgramRemovePath = '/v1/camp-program/{name}';

  /**
   * remove a program.
   *
   * Mark program as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampProgramRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampProgramRemove$Response(params: DeleteCampProgramRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<CampProgramShowResponse2>> {
    return deleteCampProgramRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a program.
   *
   * Mark program as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampProgramRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampProgramRemove(params: DeleteCampProgramRemove$Params, context?: HttpContext): Observable<CampProgramShowResponse2> {
    return this.deleteCampProgramRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampProgramShowResponse2>): CampProgramShowResponse2 => r.body)
    );
  }

  /** Path part for operation `patchCampProgramUpdate()` */
  static readonly PatchCampProgramUpdatePath = '/v1/camp-program/{name}';

  /**
   * update a program.
   *
   * update a programs data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampProgramUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampProgramUpdate$Response(params: PatchCampProgramUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampProgramShowResponse2>> {
    return patchCampProgramUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update a program.
   *
   * update a programs data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampProgramUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampProgramUpdate(params: PatchCampProgramUpdate$Params, context?: HttpContext): Observable<CampProgramShowResponse2> {
    return this.patchCampProgramUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampProgramShowResponse2>): CampProgramShowResponse2 => r.body)
    );
  }

}
