<ion-accordion-group>
  <ion-accordion value="camps" class="ion-no-padding">
    <ion-item slot="header">
      <ion-label>Ferienlagerübersicht</ion-label>
    </ion-item>

    <ion-list slot="content" lines="full">
      <ion-menu-toggle auto-hide="true">
        <ion-item routerLink="/termine" title="alle freien Termine">
          <ion-label>Freie Termine</ion-label>
        </ion-item>
        <ion-item routerLink="/ferienlager" title="Ferienlagerübersicht">
          <ion-label>Alle Ferienlager</ion-label>
        </ion-item>
        <ion-item routerLink="/ferienlager/ostern" title="Alle Ferienlager der Kategorie Frühling">
          <ion-label> Frühling </ion-label>
        </ion-item>
        <ion-item routerLink="/ferienlager/sommer" title="Alle Ferienlager der Kategorie Sommer">
          <ion-label> Sommer </ion-label>
        </ion-item>
        <ion-item
          routerLink="/ferienlager/herbstferienlager"
          title="Alle Ferienlager der Kategorie Herbst"
        >
          <ion-label> Herbst </ion-label>
        </ion-item>
        <ion-item routerLink="/ferienlager/winter" title="Alle Ferienlager der Kategorie Winter">
          <ion-label> Winter </ion-label>
        </ion-item>
        <ion-item
          routerLink="/ferienlager/auslandscamps"
          title="Alle Ferienlager der Kategorie Ausland"
        >
          <ion-label> Ausland </ion-label>
        </ion-item>
        <ion-item
          routerLink="/ferienlager/tagescamps"
          title="Alle Ferienlager der Kategorie Tagescamps"
        >
          <ion-label> Tagescamps </ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-list>
  </ion-accordion>
  <ion-accordion value="faq" class="ion-no-padding">
    <ion-item slot="header">
      <ion-label>Fragen und Informationen</ion-label>
    </ion-item>

    <ion-list slot="content" lines="full">
      <ion-menu-toggle auto-hide="true">
        <ion-item routerLink="/wer-ist-kinder-ferienlager-com" alt="Über uns">
          <ion-label>Wer ist Kinder-Ferienlager.com?</ion-label>
        </ion-item>
        <ion-item
          routerLink="/wichtige-hinweise"
          alt="Alles was Sie über Ferienlager wissen müssen"
        >
          <ion-label>Wichtige Hinweise zum Ferienlager</ion-label>
        </ion-item>
        <ion-item routerLink="/faqs_ferienlagerangebote" alt="Häufig gestellte Fragen">
          <ion-label>FAQs zu unseren Ferienlagerangeboten</ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-list>
  </ion-accordion>
  <ion-list class="ion-no-padding" lines="full">
    <ion-menu-toggle auto-hide="true">
      <ion-item routerLink="/bilder" alt="Bildergallerien">
        <ion-label> Bilder </ion-label>
      </ion-item>

      <ion-item routerLink="/aktuelles" alt="Unser Blog">
        <ion-label>Aktuelles</ion-label>
      </ion-item>

      <ion-item routerLink="/betreuer-gesucht" alt="Werden Sie Betreuer">
        <ion-label>Betreuerportal</ion-label>
      </ion-item>

      <ion-item routerLink="/impressum" alt="Rechtliches">
        <ion-label>Impressum</ion-label>
      </ion-item>

      <ion-item routerLink="/datenschutz" alt="Alles zum Thema Datenschutz">
        <ion-label>Datenschutz</ion-label>
      </ion-item>
    </ion-menu-toggle>
  </ion-list>
  <address>
    <ion-accordion value="contact">
      <ion-item slot="header">
        <ion-label>Kontakt</ion-label>
      </ion-item>

      <ion-list slot="content" lines="full">
        <ion-item>
          <ion-label>Leuchtturm Thüringen e.V.</ion-label>
        </ion-item>
        <ion-item>
          <ion-label>Bahnhofstraße 8</ion-label>
        </ion-item>
        <ion-item>
          <ion-label>99084 Erfurt</ion-label>
        </ion-item>
        <ion-item>
          <ion-label><a href="tel:03614230623">0361 4230 623</a></ion-label>
        </ion-item>
        <ion-item>
          <ion-label
            ><a href="mailto:info&#64;kinder-ferienlager.com"
              >info&#64;kinder-ferienlager.com</a
            ></ion-label
          >
        </ion-item>
      </ion-list>
    </ion-accordion>
  </address>
</ion-accordion-group>
<div class="ion-margin-horizontal ion-padding-top">
  <ion-card class="ion-margin-horizontal ion-margin-top">
    <ion-card-content class="ion-text-center">
      <app-img
        [image]="{
          cdnBasePath:
            'https://imagedelivery.net/rNFpJfph1XeZpzBC6NTnIA/0ef780cd-2cfc-408d-c330-716951bd8300',
          alt: 'Das Kinder-Ferienlager.com Männchen',
        }"
        imageWidth="184"
        cdnImageWidth="184"
      ></app-img>
    </ion-card-content>
  </ion-card>

  <ion-item lines="none">
    <ion-label>
      <p class="ion-text-center">
        © 2022
        <a href="https://kinder-ferienlager.com" alt="Copyright KidFl">Kinder-Ferienlager.com</a>
      </p>
    </ion-label>
  </ion-item>
</div>
