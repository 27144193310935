/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LocationPaginateResponse } from '../../models/location-paginate-response';

export interface GetLocationIndex$Params {

/**
 * Pagination offset
 */
  page?: number;

/**
 * Pagination limit
 */
  limit?: number;

/**
 * Pagination order field ascending
 */
  orderAsc?: 'id' | 'label';

/**
 * Pagination order field descending
 */
  orderDesc?: 'id' | 'label';

/**
 * Filter Location id
 */
  id?: number;

/**
 * Filter Location label
 */
  label?: string;
}

export function getLocationIndex(http: HttpClient, rootUrl: string, params?: GetLocationIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<LocationPaginateResponse>> {
  const rb = new RequestBuilder(rootUrl, getLocationIndex.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('limit', params.limit, {});
    rb.query('orderAsc', params.orderAsc, {});
    rb.query('orderDesc', params.orderDesc, {});
    rb.query('id', params.id, {});
    rb.query('label', params.label, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<LocationPaginateResponse>;
    })
  );
}

getLocationIndex.PATH = '/v1/location';
