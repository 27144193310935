import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImageComponent } from '@features/gallery/components/image/image.component';
import { IonicModule } from '@ionic/angular';
import { CampCategory } from '@kidfl/openapi';

@Component({
  selector: 'app-navigation-header',
  templateUrl: './navigation-header.component.html',
  standalone: true,
  imports: [CommonModule, IonicModule, ImageComponent, RouterModule],
})
export class NavigationHeaderComponent {
  @ViewChild('campsPopover') campsPopover;
  @ViewChild('fragenPopover') fragenPopover;
  isCampsPopoverOpen = false;
  isFragenPopoverOpen = false;

  categories: CampCategory[];

  clickOpenCampsPopover(event) {
    this.campsPopover.event = event;
    this.isCampsPopoverOpen = true;
  }

  clickOnFragenPopover(event) {
    this.fragenPopover.event = event;
    this.isFragenPopoverOpen = true;
  }
}
