import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import {
  provideRouter,
  Router,
  RouteReuseStrategy,
  withComponentInputBinding,
} from '@angular/router';
import { APP_ROUTES } from './app-routes';
import { provideClientHydration } from '@angular/platform-browser';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ApiModule as KidFlOpenApiModule } from '@kidfl/openapi';
import { environment } from '../environments/environment';
import { GtmService } from '@features/consent/services/gtm.service';
import { IMAGE_LOADER, ImageLoaderConfig, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { provideHttpClient, withFetch } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { SentryService } from '@features/consent/services/sentry.service';

registerLocaleData(localeDe);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(APP_ROUTES, withComponentInputBinding()),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    importProvidersFrom([
      IonicModule.forRoot(),
      KidFlOpenApiModule.forRoot({
        rootUrl: environment.apiUrl,
      }),
    ]),
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (gtmService: GtmService) => (): void => gtmService.init(),
      deps: [GtmService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        if (config.width) {
          return `${config.src.trim()}/w=${config.width}`;
        }
        return `${config.src.trim()}/default`;
      },
    },
    provideClientHydration(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [SentryService, Sentry.TraceService],
      multi: true,
    },
  ],
};
