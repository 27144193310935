import { Inject, Injectable } from '@angular/core';
import { ConsentService } from './consent.service';
import { filter, take, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  private readonly gtmSrc = 'https://www.googletagmanager.com/gtag/js?id=G-40QKYS6VN4';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private consentService: ConsentService,
  ) {}

  init(): void {
    this.consentService
      .isConsentGiven('gtm')
      .pipe(tap((enabled) => (enabled ? this.inject() : this.rejectConsent())))
      .subscribe();
  }

  private inject(): void {
    // load google tag manager with script tag

    const asyncScriptEl = this.document.createElement('script');
    asyncScriptEl.async = true;
    asyncScriptEl.src = this.gtmSrc;

    const scriptElement = this.document.createElement('script');
    scriptElement.innerHTML = `
        gtag('consent', 'update', {
          'ad_user_data': 'granted',
          'ad_personalization': 'granted',
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });      
        `;
    const head: HTMLElement = this.document.querySelector('head');
    head?.insertBefore(asyncScriptEl, head.lastElementChild.nextSibling);
    head?.insertBefore(scriptElement, head.lastElementChild.nextSibling);
  }

  private rejectConsent(): void {
    if (!this.document.querySelector(`head script[src="${this.gtmSrc}"]`)) {
      return;
    }
    const rejectScriptEl = this.document.createElement('script');
    rejectScriptEl.innerHTML = `
        gtag('consent', 'update', {
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'ad_storage': 'denied',
          'analytics_storage': 'denied'
        });      
        `;
    const head: HTMLElement = this.document.querySelector('head');
    head?.insertBefore(rejectScriptEl, head.lastElementChild.nextSibling);
  }
}
