/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrderPaginateResponse } from '../../models/order-paginate-response';

export interface GetOrderIndex$Params {

/**
 * Pagination offset
 */
  page?: number;

/**
 * Pagination limit
 */
  limit?: number;

/**
 * Pagination order field ascending
 */
  orderAsc?: 'id' | 'bookingCode';

/**
 * Pagination order field descending
 */
  orderDesc?: 'id' | 'bookingCode';

/**
 * Filter by bookingCode
 */
  bookingCode?: string;

/**
 * Filter by customer email
 */
  email?: string;

/**
 * Filter by customer-firstname
 */
  customerFirstname?: string;

/**
 * Filter by customer-lastname
 */
  customerLastname?: string;

/**
 * Filter by child-firstname
 */
  childFirstname?: string;

/**
 * Filter by child-lastname
 */
  childLastname?: string;

/**
 * Filter by trip id
 */
  trip?: number;

/**
 * Filter by camp id
 */
  camp?: number;

/**
 * Filter by program id
 */
  program?: number;

/**
 * Filter by programAdditional id
 */
  programAdditional?: number;

/**
 * Filter by departureLocation label
 */
  departureLocation?: string;

/**
 * Filter by returnLocation label
 */
  returnLocation?: string;
}

export function getOrderIndex(http: HttpClient, rootUrl: string, params?: GetOrderIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderPaginateResponse>> {
  const rb = new RequestBuilder(rootUrl, getOrderIndex.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('limit', params.limit, {});
    rb.query('orderAsc', params.orderAsc, {});
    rb.query('orderDesc', params.orderDesc, {});
    rb.query('bookingCode', params.bookingCode, {});
    rb.query('email', params.email, {});
    rb.query('customerFirstname', params.customerFirstname, {});
    rb.query('customerLastname', params.customerLastname, {});
    rb.query('childFirstname', params.childFirstname, {});
    rb.query('childLastname', params.childLastname, {});
    rb.query('trip', params.trip, {});
    rb.query('camp', params.camp, {});
    rb.query('program', params.program, {});
    rb.query('programAdditional', params.programAdditional, {});
    rb.query('departureLocation', params.departureLocation, {});
    rb.query('returnLocation', params.returnLocation, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OrderPaginateResponse>;
    })
  );
}

getOrderIndex.PATH = '/v1/order';
