import { Injectable } from '@angular/core';

declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  get nativeWindow(): any {
    return window;
  }
}
