/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomerPaginateResponse } from '../models/customer-paginate-response';
import { CustomerShowResponse } from '../models/customer-show-response';
import { CustomerShowResponse2 } from '../models/customer-show-response-2';
import { deleteCustomerRemove } from '../fn/customer/delete-customer-remove';
import { DeleteCustomerRemove$Params } from '../fn/customer/delete-customer-remove';
import { getCustomerIndex } from '../fn/customer/get-customer-index';
import { GetCustomerIndex$Params } from '../fn/customer/get-customer-index';
import { getCustomerShow } from '../fn/customer/get-customer-show';
import { GetCustomerShow$Params } from '../fn/customer/get-customer-show';
import { patchCustomerUpdate } from '../fn/customer/patch-customer-update';
import { PatchCustomerUpdate$Params } from '../fn/customer/patch-customer-update';
import { postCustomerCreate } from '../fn/customer/post-customer-create';
import { PostCustomerCreate$Params } from '../fn/customer/post-customer-create';

@Injectable({ providedIn: 'root' })
export class CustomerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCustomerIndex()` */
  static readonly GetCustomerIndexPath = '/v1/customer';

  /**
   * list customers.
   *
   * Fetch a paginated list of customers with addresses + contacts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerIndex$Response(params?: GetCustomerIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerPaginateResponse>> {
    return getCustomerIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list customers.
   *
   * Fetch a paginated list of customers with addresses + contacts
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerIndex(params?: GetCustomerIndex$Params, context?: HttpContext): Observable<CustomerPaginateResponse> {
    return this.getCustomerIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerPaginateResponse>): CustomerPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postCustomerCreate()` */
  static readonly PostCustomerCreatePath = '/v1/customer';

  /**
   * add a new customer.
   *
   * create a new customer with addresses and contacts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerCreate$Response(params: PostCustomerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShowResponse>> {
    return postCustomerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * add a new customer.
   *
   * create a new customer with addresses and contacts
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCustomerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerCreate(params: PostCustomerCreate$Params, context?: HttpContext): Observable<CustomerShowResponse> {
    return this.postCustomerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShowResponse>): CustomerShowResponse => r.body)
    );
  }

  /** Path part for operation `getCustomerShow()` */
  static readonly GetCustomerShowPath = '/v1/customer/{id}';

  /**
   * show a customer.
   *
   * Fetch a single customer with contacts and addresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerShow$Response(params: GetCustomerShow$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShowResponse2>> {
    return getCustomerShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a customer.
   *
   * Fetch a single customer with contacts and addresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerShow(params: GetCustomerShow$Params, context?: HttpContext): Observable<CustomerShowResponse2> {
    return this.getCustomerShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShowResponse2>): CustomerShowResponse2 => r.body)
    );
  }

  /** Path part for operation `deleteCustomerRemove()` */
  static readonly DeleteCustomerRemovePath = '/v1/customer/{id}';

  /**
   * remove a customer.
   *
   * Mark customer as removed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerRemove$Response(params: DeleteCustomerRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShowResponse2>> {
    return deleteCustomerRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a customer.
   *
   * Mark customer as removed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerRemove(params: DeleteCustomerRemove$Params, context?: HttpContext): Observable<CustomerShowResponse2> {
    return this.deleteCustomerRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShowResponse2>): CustomerShowResponse2 => r.body)
    );
  }

  /** Path part for operation `patchCustomerUpdate()` */
  static readonly PatchCustomerUpdatePath = '/v1/customer/{id}';

  /**
   * update a customer.
   *
   * update customer, remove all addresses & contacts and replace with the given ones
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCustomerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCustomerUpdate$Response(params: PatchCustomerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShowResponse2>> {
    return patchCustomerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update a customer.
   *
   * update customer, remove all addresses & contacts and replace with the given ones
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCustomerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCustomerUpdate(params: PatchCustomerUpdate$Params, context?: HttpContext): Observable<CustomerShowResponse2> {
    return this.patchCustomerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShowResponse2>): CustomerShowResponse2 => r.body)
    );
  }

}
