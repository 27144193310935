/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteOrderRemove } from '../fn/order/delete-order-remove';
import { DeleteOrderRemove$Params } from '../fn/order/delete-order-remove';
import { getOrderIndex } from '../fn/order/get-order-index';
import { GetOrderIndex$Params } from '../fn/order/get-order-index';
import { getOrderShow } from '../fn/order/get-order-show';
import { GetOrderShow$Params } from '../fn/order/get-order-show';
import { OrderPaginateResponse } from '../models/order-paginate-response';
import { OrderShowResponse } from '../models/order-show-response';
import { OrderStoreResponse } from '../models/order-store-response';
import { postOrderCreate } from '../fn/order/post-order-create';
import { PostOrderCreate$Params } from '../fn/order/post-order-create';

@Injectable({ providedIn: 'root' })
export class OrderService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOrderIndex()` */
  static readonly GetOrderIndexPath = '/v1/order';

  /**
   * list orders.
   *
   * Fetch paginated list of orders
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderIndex$Response(params?: GetOrderIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderPaginateResponse>> {
    return getOrderIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list orders.
   *
   * Fetch paginated list of orders
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderIndex(params?: GetOrderIndex$Params, context?: HttpContext): Observable<OrderPaginateResponse> {
    return this.getOrderIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderPaginateResponse>): OrderPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postOrderCreate()` */
  static readonly PostOrderCreatePath = '/v1/order';

  /**
   * create multiple orders.
   *
   * Create one order per child and send out system emails if dryRun=false
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderCreate$Response(params: PostOrderCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderStoreResponse>> {
    return postOrderCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * create multiple orders.
   *
   * Create one order per child and send out system emails if dryRun=false
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postOrderCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderCreate(params: PostOrderCreate$Params, context?: HttpContext): Observable<OrderStoreResponse> {
    return this.postOrderCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderStoreResponse>): OrderStoreResponse => r.body)
    );
  }

  /** Path part for operation `getOrderShow()` */
  static readonly GetOrderShowPath = '/v1/order/{id}';

  /**
   * show an order.
   *
   * Fetch a single detailed order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderShow$Response(params: GetOrderShow$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShowResponse>> {
    return getOrderShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show an order.
   *
   * Fetch a single detailed order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderShow(params: GetOrderShow$Params, context?: HttpContext): Observable<OrderShowResponse> {
    return this.getOrderShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShowResponse>): OrderShowResponse => r.body)
    );
  }

  /** Path part for operation `deleteOrderRemove()` */
  static readonly DeleteOrderRemovePath = '/v1/order/{id}';

  /**
   * remove an order.
   *
   * Mark an order as removed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrderRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderRemove$Response(params: DeleteOrderRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShowResponse>> {
    return deleteOrderRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove an order.
   *
   * Mark an order as removed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOrderRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderRemove(params: DeleteOrderRemove$Params, context?: HttpContext): Observable<OrderShowResponse> {
    return this.deleteOrderRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShowResponse>): OrderShowResponse => r.body)
    );
  }

}
