import { isPlatformServer } from '@angular/common';
import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from '@core/services';
import { AlertController } from '@ionic/angular';
import { Observable, merge, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  consentChange$ = new EventEmitter<{ name: string; consent: boolean }>();

  constructor(
    private readonly alertCtrl: AlertController,
    private readonly windowService: WindowService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId,
  ) {}

  get localStorage(): Storage {
    return this.windowService.nativeWindow?.localStorage;
  }

  shouldAskForConsent(name: string): boolean {
    if (isPlatformServer(this.platformId)) {
      return false;
    }
    return this.localStorage?.getItem(`consent.${name}`) === null;
  }

  isConsentGiven(name: string): Observable<boolean> {
    return merge(
      of(this.localStorage?.getItem(`consent.${name}`) === '1'),
      this.consentChange$.pipe(
        filter((state) => state.name === name),
        map(() => this.localStorage?.getItem(`consent.${name}`) === '1'),
      ),
    );
  }

  setConsent(name: string, value: boolean): void {
    this.localStorage?.setItem(`consent.${name}`, value ? '1' : '0');
    this.consentChange$.emit({ name, consent: value });
  }

  async askForConsent(names: string[], header: string, message: string): Promise<boolean> {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [
        {
          text: 'Mehr Infos',
          role: 'link',
          handler: () => {
            setTimeout(() => {
              this.router.navigate(['/datenschutz'], {
                queryParams: {
                  returnTo: this.windowService.nativeWindow.location.href,
                },
              });
            }, 200);
          },
        },
        {
          text: 'Ablehnen',
          role: 'cancel',
        },
        {
          text: 'Zustimmen',
          role: 'confirm',
        },
      ],
    });
    await alert?.present();
    const result = await alert.onDidDismiss();
    const consent = result.role === 'confirm';
    names.forEach((name) => this.setConsent(name, consent));
    return consent;
  }
}
