import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
})
export class FormErrorsComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() messages: { [key: string]: string };

  errorList$: Observable<
    {
      key: string;
      message: string;
    }[]
  >;

  private defaultMessages = {
    required: 'Dieses Feld ist ein Pflichtfeld.',
  };

  ngOnInit() {
    if (!this.control) {
      return;
    }
    this.errorList$ = merge([this.control.statusChanges, this.control.valueChanges]).pipe(
      map(() => this.mapErrors()),
    );
  }

  private mapErrors(): {
    key: string;
    message: string;
  }[] {
    const errorList = [];
    if (!this.control.errors) {
      return;
    }
    for (const key of Object.keys(this.control.errors)) {
      const message = this.getMessage(key);
      errorList.push({ key, message });
    }
    return errorList;
  }

  private getMessage(key: string): string {
    if (!!this.messages?.[key]) {
      return this.messages[key];
    }
    if (!!this.defaultMessages[key]) {
      return this.defaultMessages[key];
    }
    return key;
  }
}
