/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CustomerPaginateResponse } from '../../models/customer-paginate-response';

export interface GetCustomerIndex$Params {

/**
 * Pagination offset
 */
  page?: number;

/**
 * Pagination limit
 */
  limit?: number;

/**
 * Pagination order field ascending
 */
  orderAsc?: 'id';

/**
 * Pagination order field descending
 */
  orderDesc?: 'id';

/**
 * Filter customer id
 */
  id?: number;

/**
 * Filter customer person.firstname
 */
  firstname?: string;

/**
 * Filter customer person.lastname
 */
  lastname?: string;

/**
 * Filter customer email address
 */
  email?: string;
}

export function getCustomerIndex(http: HttpClient, rootUrl: string, params?: GetCustomerIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerPaginateResponse>> {
  const rb = new RequestBuilder(rootUrl, getCustomerIndex.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('limit', params.limit, {});
    rb.query('orderAsc', params.orderAsc, {});
    rb.query('orderDesc', params.orderDesc, {});
    rb.query('id', params.id, {});
    rb.query('firstname', params.firstname, {});
    rb.query('lastname', params.lastname, {});
    rb.query('email', params.email, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CustomerPaginateResponse>;
    })
  );
}

getCustomerIndex.PATH = '/v1/customer';
